// ProfilesPage.js
import React, { useState, useEffect } from 'react';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { useLocation, Link } from 'react-router-dom';
import './ProfilesPage.css';

const defaultProfileImage = '../assets/default-profile.png'; // Replace this with the correct path to your default image

// Utility function to extract URL search params
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ProfilesPage() {
  const [profiles, setProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const db = getFirestore();
  const query = useQuery();
  const goalsQuery = query.get('goals')?.toLowerCase() || '';

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const usersCollectionRef = collection(db, 'users');
        const querySnapshot = await getDocs(usersCollectionRef);
        const profilesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProfiles(profilesData);
      } catch (error) {
        console.error('Error fetching profiles:', error.message);
      }
    };

    fetchProfiles();
  }, [db]);

  useEffect(() => {
    // Filter profiles based on goals
    if (goalsQuery) {
      setFilteredProfiles(
        profiles.filter((profile) => 
          profile.goals?.toLowerCase().includes(goalsQuery)
        )
      );
    } else {
      setFilteredProfiles(profiles);
    }
  }, [profiles, goalsQuery]);

  return (
    <div>
      {filteredProfiles.map((profile) => (
          <li key={profile.id} className="profile-item">
            <Link to={`/UserProfile/${profile.userId}`} className="profile-link">
              <img
                src={profile.imageUrl || defaultProfileImage}
                alt={profile.name}
                className="profile-image"
              />
            </Link>
              <div className="profile-details">
                <strong>{profile.name}</strong>
                <p>Location: {profile.location || 'Not specified'}</p>
                <p>Goals: {profile.goals || 'Not specified'}</p>
              </div>
          </li>
      ))}
    </div>
  );
}

export default ProfilesPage;

