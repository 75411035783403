import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // Adjust the path as needed
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

function HomePage() {
    const { currentUser } = useAuth();
    const [userId, setUserId] = useState(null);
    const db = getFirestore();

  // Retrieve the friendly userId using the authenticated user's UID
  useEffect(() => {
    const fetchUserId = async () => {
      if (currentUser) {
        try {
          const usersCollectionRef = collection(db, 'users');
          const q = query(usersCollectionRef, where('uid', '==', currentUser.uid));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const userData = querySnapshot.docs[0].data();
            setUserId(userData.userId);
          } else {
            console.warn('No user found for the current authenticated user');
          }
        } catch (error) {
          console.error('Error fetching user ID:', error.message);
        }
      }
    };
    fetchUserId();
  }, [currentUser, db]);

    return (
        <div className="homepage">
            <header>
                {/* Header content if any */}
            </header>
            {!currentUser && (
                <div className="login-signup-box">
                    <div className="login-signup-content">
                        <h1>Welcome to ClimberLink!</h1>
                        <p>Connect and plan adventures with climbers around the world.</p>
                        <Link to="/login" className="button">Log In</Link>
                        <Link to="/signup" className="button button-primary">Sign Up</Link>
                        <section className="features">
                            <ul>
                                <h2>Why Join ClimberLink?</h2>
                                <li>Find climbing partners easily.</li>
                                <li>Create and share your climbing resume.</li>
                                <li>Organize and join expeditions.</li>
                            </ul>
                        </section>
                    </div>
                </div>
            )}
            {currentUser && (
                <div className="login-signup-box">
                    <p>Welcome back, Ready for your next adventure?</p>
                    {/* Use the userId to dynamically create the link to the profile page */}
{/*                    <Link to={`/UserProfile/${userId}`} className="button">Go to Profile</Link>*/}
                    <Link to={`/Trips`} className="button">Find Trips</Link>
                </div>
            )}
        </div>
    );
}

export default HomePage;

