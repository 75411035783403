import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom'; // Using react-router for navigation
import SignUpComponent from './SignUpComponent';
import { Link } from 'react-router-dom';

function SignUpPage() {
  const { currentUser } = useAuth();

  if (currentUser) {
    return <Navigate to="/" />; // Redirect to profile if logged in
  }

  return (
    <div className="homepage">
       <div className="login-signup-box">
         <div className="login-signup-content">
      <h1>Sign Up</h1>
      <SignUpComponent />
      <p>Already have an account? <Link to="/login">Log in</Link></p>
    </div>
  </div>
</div>
  );
}

export default SignUpPage;

