import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { db } from './firebase-config';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField, Button, Box, Pagination } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

function EducationTable({ userId }) {
  const [education, setEducation] = useState([]);
  const [editEducation, setEditEducation] = useState(null);
  const [editModeId, setEditModeId] = useState(null);
  const [newEducation, setNewEducation] = useState({ topic: '', taughtBy: '' });
  const [showAddForm, setShowAddForm] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const itemsPerPage = 5;
  const auth = getAuth();

  useEffect(() => {
    const fetchEducationAndCheckOwnership = async () => {
      if (!userId) return;
      const educationCollectionRef = collection(db, 'users', userId, 'education');
      const querySnapshot = await getDocs(educationCollectionRef);
      const fetchedEducation = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEducation(fetchedEducation);
      setNoOfPages(Math.ceil(fetchedEducation.length / itemsPerPage));
      setIsOwner(auth.currentUser && auth.currentUser.uid === userId);
    };

    fetchEducationAndCheckOwnership();
  }, [userId, auth]);

  const handleNewEducationInputChange = (event) => {
    const { name, value } = event.target;
    setNewEducation({ ...newEducation, [name]: value });
  };

  const handleEditEducationInputChange = (event) => {
    const { name, value } = event.target;
    setEditEducation(prev => ({ ...prev, [name]: value }));
  };

  const handleAddEducation = async () => {
    if (!newEducation.topic || !newEducation.taughtBy) return;

    const educationCollectionRef = collection(db, 'users', userId, 'education');
    try {
      const newDocRef = await addDoc(educationCollectionRef, newEducation);
      setEducation([...education, { id: newDocRef.id, ...newEducation }]);
      setNewEducation({ topic: '', taughtBy: '' });
      setShowAddForm(false);
      setNoOfPages(Math.ceil((education.length + 1) / itemsPerPage));
    } catch (error) {
      console.error('Error adding education:', error.message);
    }
  };

  const handleEditEducation = (id) => {
    setEditModeId(id);
    const educationToEdit = education.find(e => e.id === id);
    setEditEducation({ ...educationToEdit });
  };

  const handleSaveEducation = async () => {
    if (!editModeId) return;

    const educationRef = doc(db, 'users', userId, 'education', editModeId);
    await updateDoc(educationRef, editEducation);
    setEducation(education.map(e => (e.id === editModeId ? { ...e, ...editEducation } : e)));
    setEditModeId(null);
    setEditEducation(null);
  };

  const handleRemoveEducation = async (id) => {
    const educationRef = doc(db, 'users', userId, 'education', id);
    await deleteDoc(educationRef);
    const updatedEducation = education.filter(e => e.id !== id);
    setEducation(updatedEducation);
    setNoOfPages(Math.ceil(updatedEducation.length / itemsPerPage));
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box sx={{ width: '100%', margin: 'auto' }}>
      <Box display="flex" justifyContent="flex-end" p={2}>
        {isOwner && (
          <Button
            startIcon={<AddCircleIcon />}
            variant="contained"
            color="primary"
            onClick={() => setShowAddForm(!showAddForm)}
          >
            {showAddForm ? "Cancel" : "Add New Education"}
          </Button>
        )}
      </Box>

      {showAddForm && (
        <Box p={2} display="flex" flexDirection="column" gap={2}>
          <TextField fullWidth label="Topic" variant="outlined" value={newEducation.topic} onChange={handleNewEducationInputChange} name="topic" />
          <TextField fullWidth label="Taught By" variant="outlined" value={newEducation.taughtBy} onChange={handleNewEducationInputChange} name="taughtBy" />
          <Button variant="contained" color="primary" onClick={handleAddEducation}>Add Education</Button>
        </Box>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Topic</TableCell>
              <TableCell>Taught By</TableCell>
              {isOwner && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {education.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((e) => (
              <TableRow key={e.id}>
                {editModeId === e.id ? (
                  <>
                    <TableCell><TextField value={editEducation.topic} onChange={handleEditEducationInputChange} name="topic" /></TableCell>
                    <TableCell><TextField value={editEducation.taughtBy} onChange={handleEditEducationInputChange} name="taughtBy" /></TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleSaveEducation()}><EditIcon /></IconButton>
                      <IconButton onClick={() => setEditModeId(null)}><DeleteIcon /></IconButton>
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>{e.topic}</TableCell>
                    <TableCell>{e.taughtBy}</TableCell>
                    {isOwner && (
                      <TableCell>
                        <IconButton onClick={() => handleEditEducation(e.id)}><EditIcon /></IconButton>
                        <IconButton onClick={() => handleRemoveEducation(e.id)}><DeleteIcon /></IconButton>
                      </TableCell>
                    )}
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={noOfPages}
        page={page}
        onChange={handlePageChange}
	style={{ padding: 20 }}
	component="div"
	variant="outlined"
        color="primary"
	shape="rounded"
      />
    </Box>
  );
}

export default EducationTable;

