import React, { useContext, useState, useEffect, createContext } from 'react';
import { auth } from '../components/firebase-config'; // ensure correct path
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    // Function to sign up users
    function signup(email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    }

    // Function to sign in users
    function login(email, password) {
        return signInWithEmailAndPassword(auth, email, password);
    }

    // Function to log out
    function logout() {
        return signOut(auth);
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
            setCurrentUser(user);
            setLoading(false);
        });

        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        signup,
        login,
        logout
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}

