import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const auth = getAuth();
    const db = getFirestore();

    const handleLogin = async (event) => {
        event.preventDefault();

        try {
            // Authenticate the user
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Retrieve the `userId` for this authenticated user
            const usersCollection = collection(db, 'users');
            const q = query(usersCollection, where('uid', '==', user.uid));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                // Assuming that the first document is the correct one
                const userDoc = querySnapshot.docs[0];
                const { userId } = userDoc.data();

                // Redirect to the profile page using the `userId`
                navigate(`/UserProfile/${userId}`);
            } else {
                setError('User ID not found in Firestore');
            }
        } catch (error) {
            setError("Failed to log in: " + error.message);
        }
    };

    return (
        <div className="homepage">
            <div className="login-signup-box">
                <div className="login-signup-content">
                    <h1>Welcome Back!</h1>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <form onSubmit={handleLogin}>
                        <div>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                                required
                            />
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                                required
                            />
                        </div>
                        <button type="submit">Login</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;

