import React, { useState, useEffect } from 'react';
import { db } from './firebase-config';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import withOwnership from './withOwnership';
import editIcon from '../assets/edit-icon.png';
import deleteIcon from '../assets/trash.png';

const availableSkills = [
  "Rock Climbing", "Skiing", "Running", "Weight Lifting", "Swimming", "Cycling"
];

function SkillBlocks({ totalBlocks, activeBlocks }) {
  const blocks = [];
  for (let i = 0; i < totalBlocks; i++) {
    blocks.push(
      <span key={i} style={{
        width: '10px',
        height: '10px',
        backgroundColor: i < activeBlocks ? '#000080' : '#D3D3D3',
        display: 'inline-block',
        margin: '1px'
      }} />
    );
  }
  return <div>{blocks}</div>;
}

function Skills({ userId, isOwner, currentUserUid }) {
  const [skills, setSkills] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState('');
  const [newSkillLevel, setNewSkillLevel] = useState(1);
  const [editSkill, setEditSkill] = useState(null);
  const [editModeId, setEditModeId] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);

  useEffect(() => {
    const fetchSkills = async () => {
      if (!userId) {
        console.error("User ID is required.");
        return;
      }

      const skillsCollectionRef = collection(db, 'users', userId, 'skills');

      try {
        const querySnapshot = await getDocs(skillsCollectionRef);
        const fetchedSkills = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setSkills(fetchedSkills);
      } catch (error) {
        console.error("Error fetching skills:", error.message);
      }
    };

    fetchSkills();
  }, [userId]);

  const handleAddSkill = async () => {
    if (!selectedSkill || newSkillLevel < 1 || newSkillLevel > 5) return;

    const skillExists = skills.some(skill => skill.name === selectedSkill);
    if (skillExists) {
      console.warn(`Skill "${selectedSkill}" already exists for this user.`);
      return;
    }

    const skillsCollectionRef = collection(db, 'users', userId, 'skills');
    const newSkill = { name: selectedSkill, level: Number(newSkillLevel) };

    try {
      const newDocRef = await addDoc(skillsCollectionRef, newSkill);
      setSkills([...skills, { id: newDocRef.id, ...newSkill }]);
      setSelectedSkill('');
      setNewSkillLevel(1);
      setShowAddForm(false); // Hide the form after adding the skill
    } catch (error) {
      console.error("Error adding skill:", error.message);
    }
  };

  const handleEditSkill = (id) => {
    setEditModeId(id);
    const skillToEdit = skills.find((s) => s.id === id);
    setEditSkill({ ...skillToEdit });
  };

  const handleSaveSkill = async () => {
    if (editModeId) {
      const skillDocRef = doc(db, 'users', userId, 'skills', editModeId);
      try {
        await updateDoc(skillDocRef, editSkill);
        setSkills(skills.map((s) => (s.id === editModeId ? { ...s, ...editSkill } : s)));
        setEditModeId(null);
        setEditSkill(null);
      } catch (error) {
        console.error("Error updating skill:", error.message);
      }
    }
  };

  const handleRemoveSkill = async (skillId) => {
    try {
      const skillDocRef = doc(db, 'users', userId, 'skills', skillId);
      await deleteDoc(skillDocRef);
      setSkills(skills.filter((skill) => skill.id !== skillId));
    } catch (error) {
      console.error("Error removing skill:", error.message);
    }
  };

  const handleEditInputChange = (event) => {
    const { name, value } = event.target;
    setEditSkill({ ...editSkill, [name]: value });
  };

  return (
<div>
      <ul>
        {skills.map((skill) => (
          <li key={skill.id} style={{ display: 'flex', alignItems: 'center' }}>
            {editModeId === skill.id ? (
              <>
                <input type="text" value={editSkill.name} onChange={handleEditInputChange} name="name" />
                <input type="number" value={editSkill.level} min="1" max="5" onChange={handleEditInputChange} name="level" />
                <button onClick={handleSaveSkill}>Save</button>
                <button onClick={() => setEditModeId(null)}>Cancel</button>
              </>
            ) : (
              <>
                {skill.name} (Level: {skill.level})
                <SkillBlocks totalBlocks={5} activeBlocks={skill.level} />
                {isOwner && (
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
                    <img
                      src={editIcon}
                      alt="Edit"
                      onClick={() => handleEditSkill(skill.id)}
                      style={{ cursor: 'pointer', width: '24px', height: '24px', marginRight: '8px' }}
                    />
                    <img
                      src={deleteIcon}
                      alt="Delete"
                      onClick={() => handleRemoveSkill(skill.id)}
                      style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                    />
                  </div>
                )}
              </>
            )}
          </li>
        ))}
      </ul>
      {isOwner && (
        <>
          {!showAddForm && (
            <button onClick={() => setShowAddForm(true)}>Add Skill</button>
          )}

          {showAddForm && (
            <>
              <select value={selectedSkill} onChange={(e) => setSelectedSkill(e.target.value)}>
                <option value="">Select a Skill</option>
                {availableSkills.map((skill) => (
                  <option key={skill} value={skill}>{skill}</option>
                ))}
              </select>
              <input
                type="number"
                value={newSkillLevel}
                onChange={(e) => setNewSkillLevel(Math.max(1, Math.min(5, e.target.value)))}
                placeholder="Skill Level"
                min="1"
                max="5"
              />
              <button onClick={handleAddSkill}>Add Skill</button>
              <button onClick={() => setShowAddForm(false)}>Cancel</button>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default withOwnership(Skills);

