import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css'; // Importing App-specific styles
import { AuthProvider } from './contexts/AuthContext';
import HomePage from './components/HomePage';
import SignUpPage from './components/SignUpPage';
import LoginPage from './components/LoginPage';
import TopMenuBar from './components/TopMenuBar';
import UserProfile from './components/UserProfile';
import ProfilesPage from './components/ProfilesPage';
import ProfilesSearchResults from './components/ProfilesSearchResults';
import Trips from './components/Trips';
import AthleticHighlights from './components/AthleticHighlights';
import './index.css';

function App() {
  return (
    <Router>
      <AuthProvider> {/* Wrapping the entire app with AuthProvider */}
        <TopMenuBar />
        <div className="App">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/Trips" element={<Trips />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            {/* Dynamic route for UserProfile */}
            <Route path="/UserProfile/:userId" element={<UserProfile />} />
            <Route path="/profiles" element={<ProfilesPage />} />
	    <Route path="/profiles" element={<ProfilesSearchResults />} />
	    <Route path="/AthleticHighlights" element={<AthleticHighlights />} />
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;

