import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const SignUpComponent = () => {
    const auth = getAuth();
    const db = getFirestore();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSignUp = async (event) => {
        event.preventDefault();
        let newUserId;

        try {
            // Access the centralized counter document to fetch and increment the friendly user ID
            const counterDoc = doc(db, 'counters', 'lastUserId');
            const counterSnap = await getDoc(counterDoc);
            if (counterSnap.exists()) {
                newUserId = counterSnap.data().value + 1;
                await updateDoc(counterDoc, { value: newUserId });
            } else {
                throw new Error('Unable to fetch the last user ID.');
            }

            // Create the new user with Firebase Authentication
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Store the new user data in Firestore under the user's `uid` as the document ID
            const userDocRef = doc(db, 'users', user.uid);
            await setDoc(userDocRef, {
                uid: user.uid, // Store the actual Firebase UID
                userId: newUserId, // Store the friendly user ID
                email: user.email,
                location: '',
                imageUrl: ''
            });

            // Redirect to the profile page using the friendly userId
            navigate(`/UserProfile/${newUserId}`);
        } catch (signupError) {
            console.error('Error signing up:', signupError.message);
            setError('Failed to sign up: ' + signupError.message);
        }
    };

    return (
        <form onSubmit={handleSignUp}>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
            />
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
            />
            <button type="submit">Sign Up</button>
        </form>
    );
};

export default SignUpComponent;

