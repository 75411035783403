// ProfilesSearchResults.js
import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { collection, query, where, getDocs, getFirestore } from 'firebase/firestore';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ProfilesSearchResults() {
  const [profiles, setProfiles] = useState([]);
  const db = getFirestore();
  const queryString = useQuery();
  const searchGoals = queryString.get('goals') || '';

  useEffect(() => {
    const fetchProfilesByGoals = async () => {
      try {
        // Retrieve profiles that match the goals
        const usersCollectionRef = collection(db, 'users');
        const q = query(usersCollectionRef, where('goals', '>=', searchGoals), where('goals', '<=', `${searchGoals}\uf8ff`));
        const querySnapshot = await getDocs(q);
        const profilesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProfiles(profilesData);
      } catch (error) {
        console.error('Error fetching profiles by goals:', error.message);
      }
    };

    if (searchGoals) {
      fetchProfilesByGoals();
    }
  }, [db, searchGoals]);

  return (
    <div>
      <h2>Search Results for Goals: "{searchGoals}"</h2>
      {profiles.length === 0 ? (
        <p>No profiles found matching your search criteria.</p>
      ) : (
        profiles.map((profile) => (
          <li key={profile.id} className="profile-item">
            <Link to={`/UserProfile/${profile.userId}`} className="profile-link">
              <img
                src={profile.imageUrl || '/default-profile.png'}
                alt={profile.name}
                className="profile-image"
              />
            </Link>
              <div className="profile-details">
                <strong>{profile.name}</strong>
                <p>Email: {profile.email}</p>
                <p>Location: {profile.location || 'Not specified'}</p>
              </div>
          </li>
        ))
      )}
    </div>
  );
}

export default ProfilesSearchResults;

