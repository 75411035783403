import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { updateDoc, setDoc, doc, collection, query, where, getDocs, getFirestore } from 'firebase/firestore';
import SummitsTable from './SummitsTable';
import SummitsPanel from './SummitsPanel';
import EducationTable from './EducationTable';
import Skills from './Skills';
import AthleticHighlights from './AthleticHighlights';
import ClimberScore from './ClimberScore';

function UserProfile() {
  const { userId } = useParams(); // This is the friendly userId from the URL
  const auth = getAuth();
  const db = getFirestore();
  const storage = getStorage();
  const [currentUserUid, setCurrentUserUid] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [userData, setUserData] = useState({
    uid: '',
    userId: '',
    name: '',
    location: '',
    goals: '',
    imageUrl: '',
    email: ''
  });
  const [error, setError] = useState('');
 const [imageFile, setImageFile] = useState(null);
  const [editMode, setEditMode] = useState(false);

  // Set the authenticated user's UID
  useEffect(() => {
    const currentUser = auth.currentUser;
    setCurrentUserUid(currentUser ? currentUser.uid : null);
  }, [auth]);

  // Fetch user data using the friendly `userId`
  useEffect(() => {
    const fetchUserData = async () => {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('userId', '==', parseInt(userId, 10)));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setError('No user found with the given userId.');
        return;
      }

      const userDataFromDoc = querySnapshot.docs[0].data();
      setUserData(userDataFromDoc);

      // Check ownership
      const isOwnerCheck = userDataFromDoc.uid === currentUserUid;
      setIsOwner(isOwnerCheck);
    };

    fetchUserData();
  }, [userId, db, currentUserUid]);

  if (error) {
    return <div>Error: {error}</div>;
  }

// Save updated user data and profile picture
  const handleSave = async () => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      setError('User is not authenticated.');
      return;
    }

    try {
      const userDocRef = doc(db, 'users', userData.uid);

      // Update Firestore document directly (create or update)
      await setDoc(userDocRef, {
        name: userData.name,
        location: userData.location,
        goals: userData.goals,
        email: userData.email,
        imageUrl: userData.imageUrl
      }, { merge: true });

      // If there's a new profile picture, upload it
      if (imageFile) {
        const storageReference = storageRef(storage, `profile_images/${userData.uid}`);
        const uploadResult = await uploadBytes(storageReference, imageFile);
        const newImageUrl = await getDownloadURL(uploadResult.ref);

        await updateDoc(userDocRef, { imageUrl: newImageUrl });

        setUserData((prev) => ({ ...prev, imageUrl: newImageUrl }));
      }

      setEditMode(false);
    } catch (err) {
      setError(`Error updating profile: ${err.message}`);
    }
  };

  const handleImageChange = (event) => {
    if (event.target.files[0]) {
      setImageFile(event.target.files[0]);
      setUserData((prev) => ({
        ...prev,
        imageUrl: URL.createObjectURL(event.target.files[0])
      }));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const toggleEditMode = () => setEditMode(!editMode);

  return (
    <div>
      <div className="profile-container">
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <div>
          {editMode ? (
            <input type="file" onChange={handleImageChange} />
          ) : (
            userData.imageUrl && <img src={userData.imageUrl} alt="Profile" style={{ width: '100px', height: '100px' }} />
          )}
        </div>
        <div>
          <label>Name: </label>
          {editMode ? (
            <input type="text" value={userData.name} name="name" onChange={handleChange} />
          ) : (
            <span>{userData.name}</span>
          )}
        </div>
        <div>
          <label>Location: </label>
          {editMode ? (
            <input type="text" value={userData.location} name="location" onChange={handleChange} />
          ) : (
            <span>{userData.location}</span>
          )}
        </div>
        <div>
          <label>Goals: </label>
          {editMode ? (
            <textarea value={userData.goals} name="goals" onChange={handleChange} />
          ) : (
            <span>{userData.goals}</span>
          )}
        </div>
	{isOwner && (
	<div>
        {editMode ? (
          <button onClick={handleSave}>Save</button>
        ) : (
          <button onClick={toggleEditMode}>Edit</button>
        )}
      </div>
        )}
	</div>
      <div className='skills-container'>
        <h2>Climber Score (0-100)</h2>
        <ClimberScore userId={userData.uid} />
      </div>
      <div className='skills-container'>
        <h1>Summit Experience</h1>
        <SummitsPanel userId={userData.uid} />
      </div>
      <div className='skills-container'>
        <h1>Education</h1>
        <EducationTable userId={userData.uid} />
      </div>
      <div className='skills-container'>
        <h1>Skills</h1>
        <Skills userId={userData.uid} />
      </div>
      <div className='skills-container'>
        <h1>Athletic Highlights</h1>
        <AthleticHighlights userId={userData.uid} />
      </div>
    </div>
  );
}

export default UserProfile;

