// TopMenuBar.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import '../TopMenuBar.css';

function TopMenuBar() {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [userId, setUserId] = useState(null);

 const db = getFirestore();

  // Retrieve the friendly userId using the authenticated user's UID
  useEffect(() => {
    const fetchUserId = async () => {
      if (currentUser) {
        try {
          const usersCollectionRef = collection(db, 'users');
          const q = query(usersCollectionRef, where('uid', '==', currentUser.uid));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const userData = querySnapshot.docs[0].data();
            setUserId(userData.userId);
          } else {
            console.warn('No user found for the current authenticated user');
          }
        } catch (error) {
          console.error('Error fetching user ID:', error.message);
        }
      }
    };

    fetchUserId();
  }, [currentUser, db]);
  const handleLogout = async (event) => {
    event.preventDefault();
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    navigate(`/profiles?goals=${encodeURIComponent(searchQuery)}`);
  };

  return (
    <header className="top-menu-bar">
      <div className="logo">
        <Link to="/">
          <img src={process.env.PUBLIC_URL + '/climberlinklogo.png'} alt="ClimberLink Logo" />
        </Link>
      </div>
      {/* Search form without a button */}
      <form onSubmit={handleSearchSubmit} className="search-container">
        <input
          type="text"
          placeholder="Search by goals..."
          value={searchQuery}
          onChange={handleSearchInputChange}
          className="search-input"
        />
      </form>
      <nav>
        <ul>
          {currentUser ? (
            <>
              <li><a href="/Trips">Trips</a></li>
              <li><a href="/Profiles">Climbers</a></li>
              {userId && <li><Link to={`/UserProfile/${userId}`}>My Profile</Link></li>}
              <li><a href="/" onClick={handleLogout}>Logout</a></li>
            </>
          ) : (
	    <>
              <li><a href="/Trips">Trips</a></li>
              <li><a href="/Profiles">Climbers</a></li>
              <li><Link to="/login">Login</Link></li>
              <li><Link to="/signup">Sign Up</Link></li>
            </>
          )}
        </ul>
      </nav>
    </header>
  );
}

export default TopMenuBar;

