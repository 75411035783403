import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { db } from './firebase-config';
import { collection, addDoc, updateDoc, doc, deleteDoc, getDocs } from 'firebase/firestore';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField, TextareaAutosize, Button, Pagination, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

function SummitsPanel({ userId }) {
    const [summits, setSummits] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [summitsPerPage] = useState(10);
    const [editSummit, setEditSummit] = useState(null);
    const [editModeId, setEditModeId] = useState(null);
    const [newSummit, setNewSummit] = useState({
        name: '',
        height: '',
        summited: 'No',
        gpx: '',
        notes: ''
    });
    const [showAddForm, setShowAddForm] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const auth = getAuth();

    useEffect(() => {
        const fetchSummitsAndCheckOwnership = async () => {
            const currentUser = auth.currentUser;
            setIsOwner(currentUser && currentUser.uid === userId);

            const summitsRef = collection(db, 'users', userId, 'summits');
            const querySnapshot = await getDocs(summitsRef);
            setSummits(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        };

        if (userId) {
            fetchSummitsAndCheckOwnership();
        }
    }, [userId, auth]);

    const handleNewSummitInputChange = (event) => {
        const { name, value } = event.target;
        setNewSummit((prev) => ({ ...prev, [name]: value }));
    };

    const handleAddSummit = async () => {
        const summitsRef = collection(db, 'users', userId, 'summits');
        try {
            const docRef = await addDoc(summitsRef, newSummit);
            setSummits((prev) => [...prev, { ...newSummit, id: docRef.id }]);
            setNewSummit({ name: '', height: '', summited: 'No', gpx: '', notes: '' });
            setShowAddForm(false);
        } catch (error) {
            console.error('Error adding summit:', error);
        }
    };

    const handleEditSummit = (id) => {
        setEditModeId(id);
        const summitToEdit = summits.find((s) => s.id === id);
        setEditSummit({ ...summitToEdit });
    };

    const handleSaveSummit = async () => {
        if (editModeId) {
            const summitRef = doc(db, 'users', userId, 'summits', editModeId);
            await updateDoc(summitRef, editSummit);
            setSummits((prev) =>
                prev.map((s) => (s.id === editModeId ? { ...s, ...editSummit } : s))
            );
            setEditModeId(null);
            setEditSummit(null);
        }
    };

    const handleRemoveSummit = async (id) => {
        const summitRef = doc(db, 'users', userId, 'summits', id);
        await deleteDoc(summitRef);
        setSummits((prev) => prev.filter((summit) => summit.id !== id));
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <Paper style={{ width: '100%', margin: 'auto', overflow: 'hidden' }}>
            {isOwner && (
                <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button
                        startIcon={<AddCircleIcon />}
                        variant="contained"
                        color="primary"
                        onClick={() => setShowAddForm(!showAddForm)}
                        >
			{showAddForm ? "Cancel" : "Add New Summit"}
                    </Button>
                </Box>
            )}

            {showAddForm && (
                <Box p={2} display="flex" flexDirection="column" gap={2}>
                    <TextField label="Name" variant="outlined" fullWidth name="name" value={newSummit.name} onChange={handleNewSummitInputChange} />
                    <TextField label="Height" variant="outlined" fullWidth name="height" value={newSummit.height} onChange={handleNewSummitInputChange} />
                    <TextField
                        select
                        label="Summited"
                        fullWidth
                        variant="outlined"
                        SelectProps={{ native: true }}
                        value={newSummit.summited}
                        onChange={handleNewSummitInputChange}
                        name="summited"
                    >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </TextField>
                    <TextField label="GPX Link" variant="outlined" fullWidth name="gpx" value={newSummit.gpx} onChange={handleNewSummitInputChange} />
                    <TextareaAutosize minRows={3} placeholder="Notes" style={{ width: '100%' }} value={newSummit.notes} onChange={handleNewSummitInputChange} name="notes" />
                    <Button variant="contained" color="primary" onClick={handleAddSummit}>Add Summit</Button>
                </Box>
            )}

            <TableContainer component={Paper} style={{ maxHeight: 440 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Height</TableCell>
                            <TableCell>Summited</TableCell>
                            <TableCell>GPX Link</TableCell>
                            <TableCell>Notes</TableCell>
                            {isOwner && <TableCell>Actions</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {summits.slice((currentPage - 1) * summitsPerPage, currentPage * summitsPerPage).map((summit) => (
                            <TableRow key={summit.id}>
                                <TableCell>{summit.name}</TableCell>
                                <TableCell>{summit.height}</TableCell>
                                <TableCell>{summit.summited}</TableCell>
                                <TableCell><a href={summit.gpx} target="_blank" rel="noopener noreferrer">GPX Link</a></TableCell>
                                <TableCell>{summit.notes}</TableCell>
                                {isOwner && (
                                    <TableCell>
                                        <IconButton onClick={() => handleEditSummit(summit.id)}><EditIcon /></IconButton>
                                        <IconButton onClick={() => handleRemoveSummit(summit.id)}><DeleteIcon /></IconButton>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                count={Math.ceil(summits.length / summitsPerPage)}
                page={currentPage}
                onChange={handleChangePage}
                style={{ padding: 20 }}
                component="div"
                variant="outlined"
                color="primary"
                shape="rounded"
            />
        </Paper>
    );
}

export default SummitsPanel;

