import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getDoc, collection, addDoc, getDocs, updateDoc, deleteDoc, doc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from './firebase-config';
import { useNavigate } from 'react-router-dom';
import './Trips.css'; // Create and import a separate CSS file for styles

function Trips() {
  const [trips, setTrips] = useState([]);
  const [newTrip, setNewTrip] = useState({ name: '', location: '', date: '', description: '' });
  const [editTrip, setEditTrip] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [currentUserProfile, setCurrentUserProfile] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTrips = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'trips'));
        const fetchedTrips = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setTrips(fetchedTrips);
      } catch (err) {
        setError('Unable to load trips. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchTrips();

    const fetchUserProfile = async () => {
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          setCurrentUserProfile(userDocSnapshot.data());
        }
      } else {
        console.log('No user is currently logged in.');
      }
    };

    fetchUserProfile();
  }, [currentUser]);

  const createTrip = async () => {
    if (!currentUser || !currentUserProfile) return;

    const tripData = {
      ...newTrip,
      host: currentUserProfile.name,
      hostId: currentUser.uid,
      participants: [currentUser.uid],
    };
    try {
      const tripDoc = await addDoc(collection(db, 'trips'), tripData);
      setTrips([...trips, { id: tripDoc.id, ...tripData }]);

      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, { trips: arrayUnion(tripDoc.id) });

      setNewTrip({ name: '', location: '', date: '', description: '' });
      setShowCreateForm(false);
    } catch (err) {
      setError('Unable to create the trip. Please try again later.');
    }
  };

  const joinTrip = async (tripId) => {
    if (!currentUser) {
      alert('Please log in to join this trip.');
      navigate('/signup');
      return;
    }

    try {
      const tripDocRef = doc(db, 'trips', tripId);
      await updateDoc(tripDocRef, { participants: arrayUnion(currentUser.uid) });

      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, { trips: arrayUnion(tripId) });
    } catch (err) {
      setError('Unable to join the trip. Please try again later.');
    }
  };

  const deleteTrip = async (tripId, hostId) => {
    if (currentUser?.uid !== hostId) return;

    if (window.confirm('Are you sure you want to delete this trip?')) {
      try {
        const tripDocRef = doc(db, 'trips', tripId);
        await deleteDoc(tripDocRef);

        const userDocRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userDocRef, { trips: arrayRemove(tripId) });

        setTrips(trips.filter((trip) => trip.id !== tripId));
      } catch (err) {
        setError('Unable to delete the trip. Please try again later.');
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewTrip({ ...newTrip, [name]: value });
  };

  const openEditForm = (trip) => {
    setEditTrip(trip);
  };

  const handleEditInputChange = (event) => {
    const { name, value } = event.target;
    setEditTrip({ ...editTrip, [name]: value });
  };

  const saveEditTrip = async (tripId) => {
    const tripDocRef = doc(db, 'trips', tripId);
    try {
      await updateDoc(tripDocRef, editTrip);

      setTrips(trips.map((trip) => (trip.id === tripId ? editTrip : trip)));
      setEditTrip(null);
    } catch (err) {
      setError('Unable to save the changes. Please try again later.');
    }
  };

  return (
 <div>
      {currentUser && currentUserProfile && (
        <>
          {!showCreateForm && (
            <button onClick={() => setShowCreateForm(true)}>Create Trip</button>
          )}

          {showCreateForm && (
            <>
              <h2>Create a New Climbing Trip</h2>
              <input name="name" value={newTrip.name} onChange={handleInputChange} placeholder="Trip Name" />
              <input name="location" value={newTrip.location} onChange={handleInputChange} placeholder="Location" />
              <input name="date" value={newTrip.date} onChange={handleInputChange} type="date" />
              <textarea name="description" value={newTrip.description} onChange={handleInputChange} placeholder="Description" />
              <button onClick={createTrip}>Create Trip</button>
              <button onClick={() => setShowCreateForm(false)}>Cancel</button>
            </>
          )}
        </>
      )}

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <ul className="trip-list">
          {trips.map((trip) => (
            <li key={trip.id} className="trip-container">
              {editTrip && editTrip.id === trip.id ? (
                <>
                  <input name="name" value={editTrip.name} onChange={handleEditInputChange} placeholder="Trip Name" />
                  <input name="location" value={editTrip.location} onChange={handleEditInputChange} placeholder="Location" />
                  <input name="date" value={editTrip.date} onChange={handleEditInputChange} type="date" />
                  <textarea name="description" value={editTrip.description} onChange={handleEditInputChange} placeholder="Description" />
                  <button onClick={() => saveEditTrip(trip.id)}>Save Changes</button>
                  <button onClick={() => setEditTrip(null)}>Cancel</button>
                </>
              ) : (
                <>
                  <h3>{trip.name}</h3>
                  <p>Location: {trip.location}</p>
                  <p>Date: {trip.date}</p>
                  <p>Host: {trip.host}</p>
                  <p>Participants: {trip.participants?.length || 0}</p>
                  <p>Details: {trip.description}</p>
                  <button onClick={() => joinTrip(trip.id)}>Join Trip</button>
                  {currentUser?.uid === trip.hostId && (
                    <>
                      <button onClick={() => openEditForm(trip)}>Edit Trip</button>
                      <button onClick={() => deleteTrip(trip.id, trip.hostId)}>Delete Trip</button>
                    </>
                  )}
                </>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Trips;

