import React, { useState, useEffect } from 'react';
import { db } from './firebase-config';
import { collection, getDocs } from 'firebase/firestore';

function ClimberScore({ userId }) {
  const [rankScore, setRankScore] = useState(0);

  useEffect(() => {
    const fetchSummitsAndCalculateScore = async () => {
      if (!userId) {
        console.error("User ID is required.");
        return;
      }

      const summitsCollectionRef = collection(db, 'users', userId, 'summits');

      try {
        const querySnapshot = await getDocs(summitsCollectionRef);
        const fetchedSummits = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        console.log("Fetched Summits:", fetchedSummits); // Debugging
        calculateRankScore(fetchedSummits);
      } catch (error) {
        console.error("Error fetching summits:", error.message);
      }
    };

    fetchSummitsAndCalculateScore();
  }, [userId]);

  const calculateRankScore = (summits) => {
    const numSummits = summits.length;
    const totalHeight = summits.reduce((acc, summit) => {
      const height = parseInt(summit.height, 10);
      return acc + (isNaN(height) ? 0 : height);
    }, 0);

    console.log(`Number of Summits: ${numSummits}, Total Height: ${totalHeight}`);

    // Define maximum values for normalization
    const maxSummits = 10;
    const maxTotalHeight = 1000000; // Example maximum height

    // Normalize scores
    const normalizedSummits = Math.min((numSummits / maxSummits) * 50, 50); // Summit contributes max 50 points
    const normalizedHeight = Math.min((totalHeight / maxTotalHeight) * 50, 50); // Height contributes max 50 points

    // Combine normalized scores to get the final score
    const score = Math.round(normalizedSummits + normalizedHeight);
    console.log(`Calculated Score (0-100): ${score}`);
    setRankScore(score);
  };

  return (
    <div>
      <h3>{rankScore}</h3>
    </div>
  );
}

export default ClimberScore;

