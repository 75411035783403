// withOwnership.js
import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';

function withOwnership(WrappedComponent) {
  return function (props) {
    const [currentUserUid, setCurrentUserUid] = useState(null);
    const [isOwner, setIsOwner] = useState(false);

    useEffect(() => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        setCurrentUserUid(user.uid);
        setIsOwner(user.uid === props.userId); // Comparing current user ID with the component's userId prop
      } else {
        setCurrentUserUid(null);
        setIsOwner(false);
      }

      // Log the current authenticated user UID
      console.log(`Current authenticated user UID: ${user ? user.uid : 'No user authenticated'}`);
    }, [props.userId]);

    return (
      <WrappedComponent
        {...props}
        currentUserUid={currentUserUid}
        isOwner={isOwner}
      />
    );
  };
}

export default withOwnership;

