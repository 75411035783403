import React, { useState, useEffect } from 'react';
import { getFirestore, doc, collection, addDoc, updateDoc, deleteDoc, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField, TextareaAutosize, Button, Pagination, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

function AthleticHighlights({ userId }) {
  const [highlights, setHighlights] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [highlightsPerPage] = useState(10);
  const [editHighlight, setEditHighlight] = useState(null);
  const [editModeId, setEditModeId] = useState(null);
  const [newHighlight, setNewHighlight] = useState({ title: '', description: '', date: '', achievement: '' });
  const [showAddForm, setShowAddForm] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const fetchHighlights = async () => {
      const userDocRef = doc(db, 'users', userId);
      const highlightsCollectionRef = collection(userDocRef, 'athleticHighlights');
      const querySnapshot = await getDocs(highlightsCollectionRef);
      setHighlights(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      const currentUser = auth.currentUser;
      setIsOwner(currentUser && currentUser.uid === userId);
    };

    if (userId) {
      fetchHighlights();
    }
  }, [userId, db, auth]);

  const handleNewHighlightInputChange = (event) => {
    const { name, value } = event.target;
    setNewHighlight({ ...newHighlight, [name]: value });
  };

  const handleEditHighlightInputChange = (event) => {
    const { name, value } = event.target;
    setEditHighlight({ ...editHighlight, [name]: value });
  };

  const handleAddHighlight = async () => {
    const userDocRef = doc(db, 'users', userId);
    const highlightsCollectionRef = collection(userDocRef, 'athleticHighlights');
    try {
      const docRef = await addDoc(highlightsCollectionRef, newHighlight);
      setHighlights([...highlights, { ...newHighlight, id: docRef.id }]);
      setNewHighlight({ title: '', description: '', date: '', achievement: '' });
      setShowAddForm(false);
    } catch (error) {
      console.error('Error adding highlight:', error);
    }
  };

  const handleEditHighlight = (id) => {
    setEditModeId(id);
    const highlightToEdit = highlights.find((h) => h.id === id);
    setEditHighlight({ ...highlightToEdit });
  };

  const handleSaveHighlight = async () => {
    if (editModeId) {
      const userDocRef = doc(db, 'users', userId);
      const highlightRef = doc(userDocRef, 'athleticHighlights', editModeId);

      await updateDoc(highlightRef, editHighlight);
      setHighlights(highlights.map((h) => (h.id === editModeId ? { ...h, ...editHighlight } : h)));
      setEditModeId(null);
      setEditHighlight(null);
    }
  };

  const handleRemoveHighlight = async (id) => {
    const userDocRef = doc(db, 'users', userId);
    const highlightRef = doc(userDocRef, 'athleticHighlights', id);

    await deleteDoc(highlightRef);
    setHighlights(highlights.filter((highlight) => highlight.id !== id));
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <Paper style={{ width: '100%', margin: 'auto', overflow: 'hidden' }}>
      {isOwner && (
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            startIcon={<AddCircleIcon />}
            variant="contained"
            color="primary"
            onClick={() => setShowAddForm(!showAddForm)}
          >
            {showAddForm ? "Cancel" : "Add New Highlight"}
          </Button>
        </Box>
      )}

      {showAddForm && (
        <Box p={2} display="flex" flexDirection="column" gap={2}>
          <TextField label="Title" variant="outlined" fullWidth name="title" value={newHighlight.title} onChange={handleNewHighlightInputChange} />
          <TextareaAutosize minRows={3} placeholder="Description" style={{ width: '100%' }} value={newHighlight.description} onChange={handleNewHighlightInputChange} name="description" />
          <TextField type="date" label="Date" variant="outlined" fullWidth name="date" value={newHighlight.date} onChange={handleNewHighlightInputChange} />
          <TextField label="Achievement" variant="outlined" fullWidth name="achievement" value={newHighlight.achievement} onChange={handleNewHighlightInputChange} />
          <Button variant="contained" color="primary" onClick={handleAddHighlight}>Add Highlight</Button>
        </Box>
      )}

      <TableContainer component={Paper} style={{ maxHeight: 440 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Achievement</TableCell>
              {isOwner && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {highlights.slice((currentPage - 1) * highlightsPerPage, currentPage * highlightsPerPage).map((highlight) => (
              <TableRow key={highlight.id}>
                {editModeId === highlight.id ? (
                  <>
                    <TableCell><TextField fullWidth variant="outlined" value={editHighlight.title} onChange={handleEditHighlightInputChange} name="title" /></TableCell>
                    <TableCell><TextareaAutosize minRows={3} style={{ width: '100%' }} value={editHighlight.description} onChange={handleEditHighlightInputChange} name="description" /></TableCell
                    ><TableCell><TextField type="date" fullWidth variant="outlined" value={editHighlight.date} onChange={handleEditHighlightInputChange} name="date" /></TableCell>
                    <TableCell><TextField fullWidth variant="outlined" value={editHighlight.achievement} onChange={handleEditHighlightInputChange} name="achievement" /></TableCell>
                    {isOwner && (
                      <TableCell>
                        <Button onClick={handleSaveHighlight} variant="contained" color="primary">Save</Button>
                      </TableCell>
                    )}
                  </>
                ) : (
                  <>
                    <TableCell>{highlight.title}</TableCell>
                    <TableCell>{highlight.description}</TableCell>
                    <TableCell>{highlight.date}</TableCell>
                    <TableCell>{highlight.achievement}</TableCell>
                    {isOwner && (
                      <TableCell>
                        <IconButton onClick={() => handleEditHighlight(highlight.id)}><EditIcon /></IconButton>
                        <IconButton onClick={() => handleRemoveHighlight(highlight.id)}><DeleteIcon /></IconButton>
                      </TableCell>
                    )}
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={Math.ceil(highlights.length / highlightsPerPage)}
        page={currentPage}
        onChange={handleChangePage}
        style={{ padding: 20 }}
        component="div"
        variant="outlined"
        color="primary"
        shape="rounded"
      />
    </Paper>
  );
}

export default AthleticHighlights;

